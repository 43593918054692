import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { usePricingDisplayValue } from "@/pricing/pricingUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  MisconfiguredStripeObjectAction,
  MisconfiguredStripeObjectModalContent_FixMutation,
} from "@/stripe/components/__generated__/MisconfiguredStripeObjectModalContent_FixMutation.graphql"
import { MisconfiguredStripeObjectModalContentQuery } from "@/stripe/components/__generated__/MisconfiguredStripeObjectModalContentQuery.graphql"
import StripeUtils from "@/stripe/util/StripeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup, useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props extends TestIDProps {
  misconfiguredStripeObjectId: GlobalID
  onClose: () => void
}

function MisconfiguredStripeObjectModalContent({
  misconfiguredStripeObjectId,
  onClose,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const theme = useTheme()

  const { record } = useLazyLoadQuery<MisconfiguredStripeObjectModalContentQuery>(
    graphql`
      query MisconfiguredStripeObjectModalContentQuery($id: ID!) {
        record: node(id: $id) {
          ... on MisconfiguredStripeObject {
            id
            type
            product {
              type
            }
            pricing {
              ...pricingUtils_usePricingDisplayValue
            }
            actions {
              action
              available
              reason
            }
          }
        }
      }
    `,
    { id: misconfiguredStripeObjectId },
    // Always want to fetch the latest in case something has changed async that effects this record
    { fetchPolicy: "network-only" }
  )

  const actions = record?.actions || []

  const form = useFormStore<MisconfiguredStripeObjectModalContent_FixMutation>(
    graphql`
      mutation MisconfiguredStripeObjectModalContent_FixMutation(
        $input: FixMisconfiguredStripeObjectInput!
      ) {
        response: fixMisconfiguredStripeObject(input: $input) {
          product {
            id
            misconfiguredStripeObjects(first: 10)
              @connection(
                key: "MisconfiguredStripeObjectsList__misconfiguredStripeObjects"
              ) {
              __id
              totalCount
              edges {
                node {
                  id
                  type
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      misconfiguredStripeObjectId,
      action: "" as MisconfiguredStripeObjectAction,
    }
  )

  const pricingValue = usePricingDisplayValue({
    pricingKey: record?.pricing,
    currency: activeOrganization?.currency,
  })

  const ACTION_LABELS: Record<
    MisconfiguredStripeObjectAction,
    { title: string; description: string; successMessage: string }
  > = {
    recreate: {
      title: `Recreate ${getLabel(false)} on Stripe`,
      description: `We'll recreate the ${getLabel()} in Stripe to match your settings in Disco.`,
      successMessage: `This ${getLabel()} has been recreated on Stripe. Payments are now enabled.`,
    },
    make_free: {
      title: `Make ${getLabel(false)} Free on Disco`,
      description: "If you no longer want to accept payments and want to make it free.",
      successMessage: `This ${getLabel()} is now free.`,
    },
    unarchive: {
      title: `Unarchive ${getLabel(false)} on Stripe`,
      description: `We'll unarchive the ${getLabel()} in Stripe.`,
      successMessage: `This ${getLabel()} has been unarchived on Stripe. Payments are now enabled.`,
    },
    reset: {
      title: "Reset Price on Stripe",
      description: `We'll reset the price to ${pricingValue} in Stripe to match your settings in Disco.`,
      successMessage: `The price has been reset on Stripe.`,
    },
    "%future added value": {
      title: "",
      description: "",
      successMessage: "",
    },
  }

  if (!record?.type) return null

  return (
    <div className={classes.container}>
      <div className={classes.iconWrapper}>
        <DiscoIcon
          icon={"warning"}
          color={theme.palette.text.danger}
          width={24}
          height={24}
        />
      </div>

      <DiscoText variant={"body-lg-600"}>{`Resolve ${
        StripeUtils.MISCONFIGURED_OBJECT_LABELS[record.type].title
      }`}</DiscoText>

      <DiscoFormControl
        error={Boolean(form.errorsByField.status)}
        errorMessages={form.errorsByField.status}
      >
        <RadioGroup
          onChange={handleSelectAction}
          value={form.state.action}
          classes={{ root: classes.radioGroup }}
        >
          {actions.map(({ action, available, reason }) => (
            <div key={action}>
              <DiscoRadioBox
                value={action}
                title={ACTION_LABELS[action].title}
                subtitle={ACTION_LABELS[action].description}
                disabled={!available}
                showRadio
                classes={{ radioRoot: classes.radioBox }}
              />

              {reason && (
                <div className={classes.reason}>
                  <DiscoIcon icon={"info"} color={theme.palette.text.disabled} />
                  <DiscoText variant={"body-sm"} color={"text.disabled"}>
                    {reason}
                  </DiscoText>
                </div>
              )}
            </div>
          ))}
        </RadioGroup>
      </DiscoFormControl>

      <div className={classes.buttons}>
        <DiscoButton onClick={onClose} variant={"outlined"} color={"grey"} width={"100%"}>
          {"Cancel"}
        </DiscoButton>

        <DiscoButton
          onClick={handleSubmit}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={form.isSubmitting || !form.state.action}
          width={"100%"}
        >
          {"Done"}
        </DiscoButton>
      </div>
    </div>
  )

  function getLabel(addPrice = true) {
    if (!record) return "Object"

    switch (record.type) {
      case "product_archived":
      case "product_deleted":
        if (!record.product) return "Product"
        switch (record.product.type) {
          case "membership_plan":
            return "Membership Plan"
          case "course":
            return experienceLabel.singular
          case "pathway":
            return "Pathway"
          case "community_event":
            return "Community Event"
          default:
            return "Product"
        }
      case "price_deleted":
      case "price_archived":
      case "price_changed":
        if (!addPrice) return "Price"
        return `Price of ${pricingValue}`
      default:
        return "Object"
    }
  }

  function handleSelectAction(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.action = event.target.value as MisconfiguredStripeObjectAction
  }

  async function handleSubmit() {
    const { didSave } = await form.submit(
      {
        misconfiguredStripeObjectId: form.state.misconfiguredStripeObjectId,
        action: form.state.action,
      },
      {
        updater: (store) => {
          const mso = store.get(misconfiguredStripeObjectId)
          if (!mso) return
          mso.invalidateRecord()
        },
      }
    )
    if (!didSave) return

    displaySuccessToast({ message: ACTION_LABELS[form.state.action].successMessage })
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  iconWrapper: {
    borderRadius: "12px",
    width: "40px",
    height: "40px",
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    backgroundColor: theme.palette.error.light,
  },
  radioGroup: {
    gap: theme.spacing(2),
  },
  radioBox: {
    width: "100%",
  },
  reason: {
    justifyContent: "flex-start",
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    width: "100%",
  },
}))

function MisconfiguredStripeObjectModalContentSkeleton() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <div className={classes.iconWrapper}>
        <DiscoIcon
          icon={"warning"}
          color={theme.palette.text.danger}
          width={24}
          height={24}
        />
      </div>

      <DiscoTextSkeleton width={"75%"} />

      <DiscoFormControl>
        <RadioGroup classes={{ root: classes.radioGroup }}>
          {range(2).map((i) => (
            <DiscoRadioBox
              key={i}
              value={String(i)}
              title={<DiscoTextSkeleton width={"200px"} />}
              subtitle={
                <>
                  <DiscoTextSkeleton width={"350px"} />
                  <DiscoTextSkeleton width={"100px"} />
                </>
              }
              showRadio
              classes={{ radioRoot: classes.radioBox }}
            />
          ))}
        </RadioGroup>
      </DiscoFormControl>

      <div className={classes.buttons}>
        <DiscoButton variant={"outlined"} color={"grey"} width={"100%"}>
          {"Cancel"}
        </DiscoButton>

        <DiscoButton disabled width={"100%"}>
          {"Done"}
        </DiscoButton>
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: observer(MisconfiguredStripeObjectModalContent),
  skeleton: MisconfiguredStripeObjectModalContentSkeleton,
})
