/**
 * @generated SignedSource<<8d14a3137220458b4fee083fd5db456a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUserNoteInput = {
  body: string;
  organizationMembershipId: string;
};
export type UserNotesCreateFormMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateUserNoteInput;
};
export type UserNotesCreateFormMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserNotesItemFragment">;
    } | null;
  };
};
export type UserNotesCreateFormMutation = {
  response: UserNotesCreateFormMutation$data;
  variables: UserNotesCreateFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotesCreateFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateUserNoteResponse",
        "kind": "LinkedField",
        "name": "createUserNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNote",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserNotesItemFragment"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserNotesCreateFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateUserNoteResponse",
        "kind": "LinkedField",
        "name": "createUserNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNote",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "addedByUser",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTest",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "node",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "UserNoteNodeEdge"
              }
            ]
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6be2c75613029ba0613c045c4ebc4144",
    "id": null,
    "metadata": {},
    "name": "UserNotesCreateFormMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotesCreateFormMutation(\n  $input: CreateUserNoteInput!\n) {\n  response: createUserNote(input: $input) {\n    node {\n      id\n      ...UserNotesItemFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserNotesItemFragment on UserNote {\n  id\n  body\n  createdAt\n  addedByUser {\n    id\n    ...ProfileAvatarWithDetailsFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "19cadf2b9b4c8d5044617551e5a1d1e1";

export default node;
