/**
 * @generated SignedSource<<a81cd4162e670b0ad28d6189856d4477>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificateCardFragment$data = {
  readonly id: string;
  readonly mediaUrl: string;
  readonly organization: {
    readonly badge: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
    readonly name: string;
  };
  readonly product: {
    readonly badge: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "CertificateCardFragment";
};
export type CertificateCardFragment$key = {
  readonly " $data"?: CertificateCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CertificateCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Badge",
    "kind": "LinkedField",
    "name": "badge",
    "plural": false,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BadgeFragment"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CertificateCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserCertificate",
  "abstractKey": null
};
})();

(node as any).hash = "6f47f884cda8deab0e4f6a23faebc6f0";

export default node;
