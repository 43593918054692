import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { MisconfiguredStripeObjectsListItemQuery } from "@/stripe/components/__generated__/MisconfiguredStripeObjectsListItemQuery.graphql"
import MisconfiguredStripeObjectModalContent from "@/stripe/components/MisconfiguredStripeObjectModalContent"
import StripeUtils from "@/stripe/util/StripeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoButton, DiscoModal } from "@disco-ui"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  misconfiguredStripeObjectId: GlobalID
}

function MisconfiguredStripeObjectsListItem({
  testid = "MisconfiguredStripeObjectsListItem",
  misconfiguredStripeObjectId,
}: Props) {
  const classes = useStyles()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { record } = useLazyLoadQuery<MisconfiguredStripeObjectsListItemQuery>(
    graphql`
      query MisconfiguredStripeObjectsListItemQuery($id: ID!) {
        record: node(id: $id) {
          ... on MisconfiguredStripeObject {
            id
            type
          }
        }
      }
    `,
    { id: misconfiguredStripeObjectId }
  )

  if (!record?.type) return null

  return (
    <>
      <DiscoAlert
        title={StripeUtils.MISCONFIGURED_OBJECT_LABELS[record.type].title}
        message={StripeUtils.MISCONFIGURED_OBJECT_LABELS[record.type].description}
        severity={"error"}
        classes={{ root: classes.listItem, standardError: classes.text }}
        cta={
          <DiscoButton color={"error"} onClick={onOpen}>
            {"Resolve"}
          </DiscoButton>
        }
      />

      <DiscoModal
        testid={`${testid}.modal`}
        isOpen={isOpen}
        onClose={onClose}
        modalContentLabel={"Resolve Stripe Issues"}
        hideCloseIcon
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        body={
          <MisconfiguredStripeObjectModalContent
            misconfiguredStripeObjectId={misconfiguredStripeObjectId}
            onClose={onClose}
          />
        }
      />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  listItem: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  text: {
    color: theme.palette.groovy.red[600],
  },
}))

export default Relay.withSkeleton({
  component: MisconfiguredStripeObjectsListItem,
  skeleton: () => null,
})
