/**
 * @generated SignedSource<<569e957dbcb5356fc36d4529fa64e21f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileNameWithTagFragment$data = {
  readonly fullName: string;
  readonly " $fragmentType": "ProfileNameWithTagFragment";
};
export type ProfileNameWithTagFragment$key = {
  readonly " $data"?: ProfileNameWithTagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileNameWithTagFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileNameWithTagFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "79db27163e6d4c5658ac65bbfed5ad8f";

export default node;
