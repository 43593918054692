import {
  SearchOptOutEntity,
  SearchOptOutRole,
} from "@/admin/appearance/__generated__/AdminAppearancePageMutation.graphql"

export const SearchTypes = [
  "post",
  "user",
  "product",
  "chatChannel",
  "feed",
  "chatMessage",
  "contentUsage",
  "occurrence",
] as const
export type SearchType = (typeof SearchTypes)[number]

export const SearchTypeLabels: Record<SearchType, string> = {
  post: "Posts",
  user: "People",
  product: "Products",
  chatChannel: "Channels",
  feed: "Feeds",
  contentUsage: "Content",
  occurrence: "Events",
  chatMessage: "Messages",
}

export const SearchTypeToOptOut: Record<SearchType, SearchOptOutEntity> = {
  post: "post",
  user: "user",
  product: "product",
  chatChannel: "chat_channel",
  feed: "feed",
  contentUsage: "content_usage",
  occurrence: "occurrence",
  chatMessage: "chat_message",
}

export const SEARCH_OPT_OUT_ROLES: SearchOptOutRole[] = ["owner", "admin", "member"]
