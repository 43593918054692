import { BasicUser } from "@/user/api/usersApiModels"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import {
  DEFAULT_USER_AVATAR_SIZE,
  USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT,
} from "@/user/common/avatar/util/userAvatarConstants"
import { getUserFullName } from "@/user/util/userUtils"
import Avatar, { AvatarShape } from "@components/avatar/Avatar"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql } from "relay-runtime"

interface UserAvatarProps extends TestIDProps {
  user: UserAvatarShape
  size?: ProfileAvatarSize
  placeholderTextClassName?: string
  placeholderClassName?: string
  className?: string
  /** If true, apply a drop shadow on the component */
  dropShadow?: boolean
  shape?: AvatarShape
}

/** The shape of the user from stream chat */
export type UserAvatarShape = Pick<BasicUser, "first_name" | "last_name" | "avatar"> & {
  name?: string
  id?: string
  is_test_user: boolean
}

function UserAvatar({
  user,
  size = DEFAULT_USER_AVATAR_SIZE,
  placeholderTextClassName,
  placeholderClassName,
  testid = "UserAvatar",
  className,
  dropShadow,
  shape,
}: UserAvatarProps) {
  const userFullName = getUserFullName(user)

  return (
    <Avatar
      alt={userFullName}
      src={user.avatar || ""}
      dropShadow={dropShadow}
      className={className}
      size={size}
      shape={shape}
      isTestUser={user.is_test_user}
      placeholder={
        <AvatarPlaceholder
          testid={`${testid}.AvatarPlaceholder`}
          text={getInitialLettersOfWords(userFullName).substring(
            0,
            USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT
          )}
          uuid={user.id}
          size={size}
          textClassName={placeholderTextClassName}
          className={placeholderClassName}
          dropShadow={dropShadow}
          style={{ height: size, width: size }}
          isTestUser={user.is_test_user}
        />
      }
    />
  )
}

// Type guard to ensure that the user is a Avatar
export function hasUserAvatarShape(user: unknown): user is UserAvatarShape {
  return (user as UserAvatarShape).avatar !== undefined
}

export default UserAvatar

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment UserAvatarFragment on User {
    id
    first_name: firstName
    last_name: lastName
    avatar
  }
`
