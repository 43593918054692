import ROUTE_NAMES from "@/core/route/util/routeNames"
import IconWithContent from "@/user/settings/common/icon-with-content/IconWithContent"
import { ProfileSettingsAccountTabEmailSectionFragment$key } from "@/user/settings/subtabs/account/__generated__/ProfileSettingsAccountTabEmailSectionFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useHistory } from "react-router-dom"

interface Props {
  userKey: ProfileSettingsAccountTabEmailSectionFragment$key
}

function ProfileSettingsAccountTabEmailSection({ userKey }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const classes = useStyles()

  const user = useFragment<ProfileSettingsAccountTabEmailSectionFragment$key>(
    graphql`
      fragment ProfileSettingsAccountTabEmailSectionFragment on User {
        email
        hasSocialAuth
        canChangeEmail
        isTest
      }
    `,
    userKey
  )

  return (
    <IconWithContent
      icon={"email"}
      content={
        <div>
          <DiscoText
            testid={"ProfileSettingsAccountTab.email"}
            color={"text.secondary"}
            marginBottom={1}
          >
            {user.email}
          </DiscoText>

          {getContent()}
        </div>
      }
    />
  )

  function getContent() {
    if (user.isTest) {
      return (
        <DiscoTooltip
          content={"You cannot change your email as a Test Member"}
          placement={"right"}
          data-testid={"ProfileSettingsAccountTab.test-user-can-not-change-email-tooltip"}
        >
          <DiscoText>{"Change Email"}</DiscoText>
        </DiscoTooltip>
      )
    }

    if (user.canChangeEmail) {
      if (user.hasSocialAuth) {
        return (
          <>
            <DiscoLink
              data-testid={"ProfileSettingsAccountTab.change-email.button"}
              onClick={openModal}
            >
              {"Change Email"}
            </DiscoLink>

            <DiscoWarningModal
              testid={"ProfileSettingsAccountTab.change-email-warning-modal"}
              variant={"primary"}
              icon={"user"}
              isOpen={isModalOpen}
              onClose={closeModal}
              title={`Change email address?`}
              confirmationButtonProps={{
                onClick: () =>
                  history.push(ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION),
                children: `Yes`,
              }}
              description={
                `Are you sure you want to change your email address? ` +
                `This account uses social auth and changing your email address will disconnect your Disco account from your social auth provider.`
              }
              modalContentLabel={`Change email dialog`}
            />
          </>
        )
      }
      return (
        <DiscoLink
          data-testid={"ProfileSettingsAccountTab.change-email.button"}
          to={ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION}
        >
          {"Change Email"}
        </DiscoLink>
      )
    }

    return (
      <div className={classes.tooltipContainer}>
        <DiscoText fontStyle={"italic"} variant={"body-sm"} color={"text.secondary"}>
          {"*Cannot change email address"}
        </DiscoText>
        <DiscoTooltip
          content={
            "Your email address is used for Single Sign On authentication in a community. Email update is disabled."
          }
          placement={"bottom"}
        />
      </div>
    )
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5), // 4px in theme spacing
  },
}))

export default ProfileSettingsAccountTabEmailSection
