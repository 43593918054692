/**
 * @generated SignedSource<<cb746fc45f6f36c1148e9ae59a5d0d3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserAvatarFragment$data = {
  readonly avatar: string;
  readonly first_name: string | null;
  readonly id: string;
  readonly last_name: string | null;
  readonly " $fragmentType": "UserAvatarFragment";
};
export type UserAvatarFragment$key = {
  readonly " $data"?: UserAvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "first_name",
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": "last_name",
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "36a727d73d7b6d293b3fbb09005f1ccf";

export default node;
