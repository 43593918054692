import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { SlackUserAvatarWithEmailFragment$key } from "@/slack/slack-user-import-report/__generated__/SlackUserAvatarWithEmailFragment.graphql"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  userKey: SlackUserAvatarWithEmailFragment$key | null
  className?: string
  classes?: ClassNameMap<"details">
  avatarSize?: ProfileAvatarSize
  truncateName?: number
  namePlaceholder?: string
} & TestIDProps

function SlackUserAvatarWithEmail(props: Props) {
  const {
    userKey,
    className: customClassName,
    classes: customClasses,
    avatarSize,
    testid = "SlackUserAvatarWithEmail",
    truncateName,
    namePlaceholder = "Unknown",
  } = props

  const slackUser = useFragment<SlackUserAvatarWithEmailFragment$key>(
    graphql`
      fragment SlackUserAvatarWithEmailFragment on SlackUserImportMember {
        id
        firstName
        lastName
        fullName
        email
        slackUserIconUrl
      }
    `,
    userKey
  )
  const classes = useStyles({ avatarSize, hasNamePlaceholder: !slackUser?.fullName })

  return (
    <div className={classNames(classes.container, customClassName)} data-testid={testid}>
      {slackUser ? (
        <UserAvatar
          user={{
            first_name: slackUser.firstName,
            last_name: slackUser.lastName,
            avatar: slackUser.slackUserIconUrl,
            is_test_user: false,
          }}
          size={24}
          shape={"square"}
        />
      ) : (
        <DiscoIcon icon={"user"} width={24} height={24} />
      )}
      <div className={classes.detailsContainer}>
        <DiscoText
          variant={slackUser?.fullName ? "body-sm-700" : "body-sm-500"}
          truncateText={truncateName}
          testid={`${testid}.member-fullname`}
          // Hide name in FS recordings
          className={classNames(classes.name, "fs-mask")}
        >
          {slackUser?.fullName || namePlaceholder}
        </DiscoText>
        {slackUser?.email && (
          <div className={classNames(classes.details, customClasses?.details)}>
            <DiscoText
              variant={"body-xs-500"}
              testid={`${testid}.details`}
              truncateText={1}
              color={"text.secondary"}
              className={classNames(classes.detailsText, "fs-mask")}
            >
              {slackUser.email}
            </DiscoText>
          </div>
        )}
      </div>
    </div>
  )
}

type StyleProps = {
  avatarSize?: number
  hasNamePlaceholder: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ avatarSize }: StyleProps) => ({
    display: "grid",
    gridTemplateColumns: `${avatarSize ?? 24}px 1fr`,
    gap: theme.spacing(1.5),
    alignItems: "center",
    minHeight: 48,
    minWidth: "min-content",
  }),
  detailsContainer: {
    minWidth: "0",
  },
  name: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    color: ({ hasNamePlaceholder }: StyleProps) =>
      hasNamePlaceholder ? theme.palette.text.hint : theme.palette.text.primary,
  },
  details: {
    height: "18px",
  },
  detailsText: {
    fontWeight: 400,
    textTransform: "inherit",
  },
}))

export default SlackUserAvatarWithEmail
