/**
 * @generated SignedSource<<01cd1c33b33e0cc9cac4c17b25c1f9fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDropdownItemPrivateFragment$data = {
  readonly email: string | null;
  readonly " $fragmentType": "UserDropdownItemPrivateFragment";
};
export type UserDropdownItemPrivateFragment$key = {
  readonly " $data"?: UserDropdownItemPrivateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDropdownItemPrivateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "PrivateUser",
  "abstractKey": "__isPrivateUser"
};

(node as any).hash = "7991025fd087d285c0ef177633d1a742";

export default node;
