import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { ProfileListModalListItemFragment$key } from "@/user/common/profile-list-modal-list-item/__generated__/ProfileListModalListItemFragment.graphql"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { graphql, useFragment } from "react-relay"
import { getUserFullName } from "../../util/userUtils"

interface ProfileListModalListItemProps {
  userKey: ProfileListModalListItemFragment$key
  testid?: string
  onClose: () => void
}

function ProfileListModalListItem({
  userKey,
  testid,
  onClose,
}: ProfileListModalListItemProps) {
  const classes = useStyles()
  const profileDrawer = useGlobalDrawer("profileSettings")

  const user = useFragment<ProfileListModalListItemFragment$key>(
    graphql`
      fragment ProfileListModalListItemFragment on User {
        id
        firstName
        lastName
        fullName
        ...ProfileAvatarFragment
      }
    `,
    userKey
  )

  const content = (
    <>
      <ProfileAvatar
        testid={`${testid}.MultipleAvatars.${user.firstName}`}
        userKey={user}
        size={32}
      />
      <DiscoText variant={"body-sm"} testid={`${testid}.name`}>
        {user.firstName
          ? user.fullName
          : getUserFullName({ first_name: user.firstName, last_name: user.lastName })}
      </DiscoText>
    </>
  )

  return user.id ? (
    <DiscoContainerButton
      className={classes.container}
      testid={`${testid}.root`}
      onClick={openProfile}
    >
      {content}
    </DiscoContainerButton>
  ) : (
    <div className={classes.container} data-testid={`${testid}.root`}>
      {content}
    </div>
  )

  function openProfile() {
    profileDrawer.open({
      drawerProfileId: user.id,
      profileSettingsTab: "profile",
    })
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}))

export default ProfileListModalListItem
