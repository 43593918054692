/**
 * @generated SignedSource<<7bfff132b258d67653fafb92956f71dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlackUserAvatarWithEmailFragment$data = {
  readonly email: string | null;
  readonly firstName: string | null;
  readonly fullName: string;
  readonly id: string;
  readonly lastName: string | null;
  readonly slackUserIconUrl: string | null;
  readonly " $fragmentType": "SlackUserAvatarWithEmailFragment";
};
export type SlackUserAvatarWithEmailFragment$key = {
  readonly " $data"?: SlackUserAvatarWithEmailFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlackUserAvatarWithEmailFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlackUserAvatarWithEmailFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackUserIconUrl",
      "storageKey": null
    }
  ],
  "type": "SlackUserImportMember",
  "abstractKey": null
};

(node as any).hash = "058c46feb734cd24db7fb2fd3fe22da8";

export default node;
