import CommentUtils from "@/comments/utils/CommentUtils"
import {
  DEFAULT_USER_AVATAR_SIZE,
  USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT,
} from "@/user/common/avatar/util/userAvatarConstants"
import { ProfileAvatarFragment$key } from "@/user/common/avatar/__generated__/ProfileAvatarFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Avatar from "@components/avatar/Avatar"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import { DiscoIcon } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import TestTagDarkLarge from "./test-tag-dark-lg.svg"
import TestTagDarkMedium from "./test-tag-dark-md.svg"
import TestTagDarkSmall from "./test-tag-dark-sm.svg"
import TestTagLightLarge from "./test-tag-light-lg.svg"
import TestTagLightMedium from "./test-tag-light-md.svg"
import TestTagLightSmall from "./test-tag-light-sm.svg"

export interface ProfileAvatarProps extends TestIDProps {
  /** This key won't exist for users that are no longer part of a product (ie. they left) */
  userKey: ProfileAvatarFragment$key | null
  size?: ProfileAvatarSize
  placeholderTextClassName?: string
  placeholderClassName?: string
  className?: string
  badgeClassName?: string
  /** If true, apply a drop shadow on the component */
  dropShadow?: boolean
  badgeIcon?: React.ReactNode
  user?: {
    id?: string
    fullName?: string
    avatar?: string | null
  }
  showTestUserTag?: boolean
}

export type ProfileAvatarSize = 20 | 24 | 32 | 40 | 48 | 64 | 96 | 120

function ProfileAvatar({
  userKey,
  size = DEFAULT_USER_AVATAR_SIZE,
  placeholderTextClassName,
  placeholderClassName,
  testid = "ProfileAvatar",
  badgeIcon,
  dropShadow,
  className,
  showTestUserTag = false,
  badgeClassName,
  user: propsUser,
}: ProfileAvatarProps) {
  const classes = useStyles()
  const theme = useTheme()

  const user = useFragment<ProfileAvatarFragment$key>(
    graphql`
      fragment ProfileAvatarFragment on User {
        id
        fullName
        avatar
        isTest
      }
    `,
    userKey
  )

  const {
    id,
    fullName = CommentUtils.getUnknownUserFullName(),
    avatar,
  } = user || propsUser || {}

  // Test users will never be in a flow where they don't have a user created yet.
  // So if they don't have a user, it's safe to assume they aren't a test user
  const isTestUser = user?.isTest || false

  return (
    <div className={classes.avatarBadgeContainer}>
      <Avatar
        alt={fullName}
        src={avatar}
        className={classnames(className, "fs-mask")}
        size={size}
        dropShadow={dropShadow}
        placeholder={
          fullName ? (
            <AvatarPlaceholder
              testid={`${testid}.AvatarPlaceholder`}
              text={getInitialLettersOfWords(fullName).substring(
                0,
                USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT
              )}
              isTestUser={isTestUser}
              uuid={id}
              size={size}
              textClassName={placeholderTextClassName}
              className={placeholderClassName}
              dropShadow={dropShadow}
            />
          ) : (
            <DiscoIcon icon={"user"} width={size} height={size} />
          )
        }
        isTestUser={isTestUser}
      />
      {badgeIcon && (
        <div className={classnames(classes.badgeContainer, badgeClassName)}>
          {badgeIcon}
        </div>
      )}
      {user?.isTest && showTestUserTag && !badgeIcon && (
        <span data-testid={`${testid}.TestUserTag`} className={classes.testUserTag}>
          {getTestTagSvg()}
        </span>
      )}
    </div>
  )

  function getTestTagSvg() {
    if (theme.palette.type === "dark") {
      if (size <= 64) {
        return <TestTagDarkSmall />
      } else if (size <= 96) {
        return <TestTagDarkMedium />
      }
      return <TestTagDarkLarge />
    }

    if (size <= 64) {
      return <TestTagLightSmall />
    } else if (size <= 96) {
      return <TestTagLightMedium />
    }
    return <TestTagLightLarge />
  }
}

const useStyles = makeUseStyles(() => ({
  avatarBadgeContainer: {
    display: "flex",
    position: "relative",
  },
  badgeContainer: {
    position: "absolute",
    right: "-4px", // negative value here so it aligns outside of the bounding div
    bottom: "-10px", // negative value here so it aligns outside of the bounding div
  },
  testUserTag: {
    display: "flex",
    position: "absolute",
    bottom: "-1px",
    right: "50%",
    transform: "translate(50%, 0)",
  },
}))

export default ProfileAvatar
