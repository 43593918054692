/**
 * @generated SignedSource<<7eddcfb1377de64780a45c6d7a8d2b83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationCheckoutVersion = "stripe" | "stripe_acacia" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsAccountTabFragment$data = {
  readonly canChangeEmail: boolean;
  readonly email: string;
  readonly hasSocialAuth: boolean;
  readonly isTest: boolean;
  readonly organizationMembership: {
    readonly organization: {
      readonly checkoutVersion: OrganizationCheckoutVersion | null;
      readonly plans: {
        readonly totalCount: number;
      };
      readonly stripeAccountId: string | null;
      readonly stripePortalConfigId: string | null;
    };
    readonly stripeConnectedCustomerId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"RemoveCommunityMemberButtonFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsAccountTabEmailSectionFragment">;
  readonly " $fragmentType": "ProfileSettingsAccountTabFragment";
};
export type ProfileSettingsAccountTabFragment$key = {
  readonly " $data"?: ProfileSettingsAccountTabFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsAccountTabFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsAccountTabFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canChangeEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSocialAuth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RemoveCommunityMemberButtonFragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeConnectedCustomerId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripeAccountId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkoutVersion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stripePortalConfigId",
              "storageKey": null
            },
            {
              "alias": "plans",
              "args": [
                {
                  "kind": "Literal",
                  "name": "hideDrafts",
                  "value": true
                },
                {
                  "kind": "Literal",
                  "name": "type",
                  "value": "membership_plan"
                }
              ],
              "concreteType": "ProductNodeConnection",
              "kind": "LinkedField",
              "name": "products",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": "products(hideDrafts:true,type:\"membership_plan\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileSettingsAccountTabEmailSectionFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c1b3c9a2f38fd28a58f93e2f8dd8aa23";

export default node;
