/**
 * @generated SignedSource<<f0ece6eec20b19617eb09a128a4607f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileAvatarStackFragment$data = {
  readonly firstName: string | null;
  readonly id: string;
  readonly lastName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileListModalListItemFragment">;
  readonly " $fragmentType": "ProfileAvatarStackFragment";
};
export type ProfileAvatarStackFragment$key = {
  readonly " $data"?: ProfileAvatarStackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarStackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileAvatarStackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileListModalListItemFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d65319f1451f668fb0083485e520a3ee";

export default node;
