import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { MisconfiguredStripeObjectsListQuery } from "@/stripe/components/__generated__/MisconfiguredStripeObjectsListQuery.graphql"
import MisconfiguredStripeObjectsListItem from "@/stripe/components/MisconfiguredStripeObjectsListItem"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

interface Props {
  productId: GlobalID
  className?: string
}

function MisconfiguredStripeObjectsList(props: Props) {
  const { productId } = props
  const classes = useStyles()

  const [{ product }, refetch] =
    Relay.useRefetchableQuery<MisconfiguredStripeObjectsListQuery>(
      graphql`
        query MisconfiguredStripeObjectsListQuery($id: ID!) {
          product: node(id: $id) {
            ... on Product {
              id
              misconfiguredStripeObjects(first: 10)
                @connection(
                  key: "MisconfiguredStripeObjectsList__misconfiguredStripeObjects"
                ) {
                __id
                totalCount
                edges {
                  node {
                    id
                    type
                  }
                }
              }
            }
          }
        }
      `,
      { id: productId },
      { fetchPolicy: "store-and-network" }
    )

  useSubscribeToInvalidationState(
    product?.misconfiguredStripeObjects ? [product.misconfiguredStripeObjects.__id] : [],
    () => refetch({ id: productId })
  )

  const records = Relay.connectionToArray(product?.misconfiguredStripeObjects)
  if (!records.length) return null

  return (
    <div className={classNames(classes.list, props.className)}>
      {records.map((record) => (
        <MisconfiguredStripeObjectsListItem
          key={record.id}
          misconfiguredStripeObjectId={record.id}
        />
      ))}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
}))

export default Relay.withSkeleton({
  component: MisconfiguredStripeObjectsList,
  skeleton: () => null,
})
