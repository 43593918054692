/**
 * @generated SignedSource<<202d7fe292cb8d403a7e67cbe8ed3908>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type OccurrenceSearchHitQuery$variables = {
  id: string;
};
export type OccurrenceSearchHitQuery$data = {
  readonly occurrence: {
    readonly datetimeRange?: ReadonlyArray<string>;
    readonly id?: string;
    readonly name?: string | null;
    readonly product?: {
      readonly name: string;
      readonly type: ProductType;
    };
  } | null;
};
export type OccurrenceSearchHitQuery = {
  response: OccurrenceSearchHitQuery$data;
  variables: OccurrenceSearchHitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datetimeRange",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrenceSearchHitQuery",
    "selections": [
      {
        "alias": "occurrence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Occurrence",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OccurrenceSearchHitQuery",
    "selections": [
      {
        "alias": "occurrence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Occurrence",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24bd87e8e3fc3dfcaba3eed88965b929",
    "id": null,
    "metadata": {},
    "name": "OccurrenceSearchHitQuery",
    "operationKind": "query",
    "text": "query OccurrenceSearchHitQuery(\n  $id: ID!\n) {\n  occurrence: node(id: $id) {\n    __typename\n    ... on Occurrence {\n      id\n      name\n      datetimeRange\n      product {\n        type\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "365e26929775e4def891a68e1ca04fab";

export default node;
