/**
 * @generated SignedSource<<935d4f19cbe41cd5f4c2042573f8bd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateStripeSetupIntentInput = {
  paymentMethodId: string;
};
export type useHandleFuturePaymentMutation$variables = {
  input: CreateStripeSetupIntentInput;
};
export type useHandleFuturePaymentMutation$data = {
  readonly createStripeSetupIntent: {
    readonly clientSecret: string | null;
    readonly errors: ReadonlyArray<{
      readonly message: string;
    }> | null;
    readonly status: string | null;
  };
};
export type useHandleFuturePaymentMutation = {
  response: useHandleFuturePaymentMutation$data;
  variables: useHandleFuturePaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateStripeSetupIntentResponse",
    "kind": "LinkedField",
    "name": "createStripeSetupIntent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleFuturePaymentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHandleFuturePaymentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f92f07cc58e0dc08a1c9a52b3c21a3b",
    "id": null,
    "metadata": {},
    "name": "useHandleFuturePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleFuturePaymentMutation(\n  $input: CreateStripeSetupIntentInput!\n) {\n  createStripeSetupIntent(input: $input) {\n    status\n    clientSecret\n    errors {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "469e9b1afda9b8239934a189dc144458";

export default node;
