import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { stopImpersonatingTestUser } from "@/product/util/hook/useInitImpersonateTestUser"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import { IMPERSONATE_USER_APP_BAR_HEIGHT } from "@/user/impersonate/ImpersonateUserUtil"
import { DiscoButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"

function ImpersonateUserAppBar() {
  const { authUser } = useAuthUser()
  const classes = useStyles()
  const theme = useTheme()

  if (!authUser || !authUser.isTest) {
    return null
  }

  return (
    <div data-testid={"ImpersonateUserAppBar"} className={classes.toolbarRoot}>
      <DiscoText
        color={theme.palette.type === "dark" ? "common.black" : "common.white"}
        variant={"body-sm-600"}
      >{`Logged in as:`}</DiscoText>

      <ProfileAvatarWithDetails
        testid={"ImpersonateUserAppBar.profile-avatar-with-details"}
        className={classes.profileAvatarWithDetailsContainer}
        userKey={authUser}
        avatarSize={24}
        linkToProfile={false}
        title={
          <DiscoText
            color={theme.palette.type === "dark" ? "common.black" : "common.white"}
            variant={"body-sm-600"}
          >
            {authUser.fullName}
          </DiscoText>
        }
      />

      <DiscoButton
        testid={"ImpersonateUserAppBar.turn-off-button"}
        color={"grey"}
        onClick={stopImpersonatingTestUser}
        size={"small"}
      >
        {"Exit"}
      </DiscoButton>
    </div>
  )
}

export default ImpersonateUserAppBar

const useStyles = makeUseStyles((theme) => ({
  toolbarRoot: {
    display: "flex",
    width: "100%",
    minHeight: `${IMPERSONATE_USER_APP_BAR_HEIGHT}px`,
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1.5),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  profileAvatarWithDetailsContainer: {
    minWidth: "unset",
    gap: theme.spacing(0.5),
  },
}))
