/**
 * @generated SignedSource<<03f14f81fc67b3757e0b31e99064c449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MisconfiguredStripeObjectAction = "make_free" | "recreate" | "reset" | "unarchive" | "%future added value";
export type MisconfiguredStripeObjectType = "price_archived" | "price_changed" | "price_deleted" | "product_archived" | "product_deleted" | "%future added value";
export type FixMisconfiguredStripeObjectInput = {
  action: MisconfiguredStripeObjectAction;
  misconfiguredStripeObjectId: string;
};
export type MisconfiguredStripeObjectModalContent_FixMutation$variables = {
  input: FixMisconfiguredStripeObjectInput;
};
export type MisconfiguredStripeObjectModalContent_FixMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly product: {
      readonly id: string;
      readonly misconfiguredStripeObjects: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly type: MisconfiguredStripeObjectType;
          };
        }>;
        readonly totalCount: number;
      };
    } | null;
  };
};
export type MisconfiguredStripeObjectModalContent_FixMutation = {
  response: MisconfiguredStripeObjectModalContent_FixMutation$data;
  variables: MisconfiguredStripeObjectModalContent_FixMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MisconfiguredStripeObjectNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MisconfiguredStripeObject",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MisconfiguredStripeObjectModalContent_FixMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "FixMisconfiguredStripeObjectResponse",
        "kind": "LinkedField",
        "name": "fixMisconfiguredStripeObject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "misconfiguredStripeObjects",
                "args": null,
                "concreteType": "MisconfiguredStripeObjectNodeConnection",
                "kind": "LinkedField",
                "name": "__MisconfiguredStripeObjectsList__misconfiguredStripeObjects_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MisconfiguredStripeObjectModalContent_FixMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "FixMisconfiguredStripeObjectResponse",
        "kind": "LinkedField",
        "name": "fixMisconfiguredStripeObject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MisconfiguredStripeObjectNodeConnection",
                "kind": "LinkedField",
                "name": "misconfiguredStripeObjects",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "misconfiguredStripeObjects(first:10)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MisconfiguredStripeObjectsList__misconfiguredStripeObjects",
                "kind": "LinkedHandle",
                "name": "misconfiguredStripeObjects"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "069d49fd57a81c8d57c679a886bbf7ff",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "response",
            "product",
            "misconfiguredStripeObjects"
          ]
        }
      ]
    },
    "name": "MisconfiguredStripeObjectModalContent_FixMutation",
    "operationKind": "mutation",
    "text": "mutation MisconfiguredStripeObjectModalContent_FixMutation(\n  $input: FixMisconfiguredStripeObjectInput!\n) {\n  response: fixMisconfiguredStripeObject(input: $input) {\n    product {\n      id\n      misconfiguredStripeObjects(first: 10) {\n        totalCount\n        edges {\n          node {\n            id\n            type\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb1f7e2f070b4236a7802d707827a9fe";

export default node;
