// Importing anything from @stripe/stripe-js directly will automatically load the Stripe
// library and Stripe will start spamming requests for fraud detection. Importing the
// loader from "/pure" avoids this. We also need to use "import type" when importing any
// types from @stripe/stripe-js as well, or else it will still load stripe to soon.
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { MisconfiguredStripeObjectType } from "@/stripe/components/__generated__/MisconfiguredStripeObjectsListQuery.graphql"
import type { Stripe } from "@stripe/stripe-js"
import { loadStripe as loadStripeLib } from "@stripe/stripe-js/pure"

namespace StripeUtils {
  let stripePromise: Promise<Stripe | null> | undefined

  /**
   * Loads the Stripe library if not already loading. Intended to be used inside a React
   * component's render so that it isn't loaded globally until necessary.
   */
  export function loadStripe(accountId?: string | undefined): Promise<Stripe | null> {
    if (!stripePromise) {
      stripePromise = loadStripeLib(STRIPE_API_KEY, { stripeAccount: accountId })
    }

    return stripePromise
  }

  export function useLoadStripe(): Promise<Stripe | null> {
    const activeOrganization = useActiveOrganization()

    switch (activeOrganization?.checkoutVersion) {
      case "stripe_acacia":
        return loadStripe(activeOrganization?.stripeAccountId ?? undefined)
      case "stripe":
      default:
        return loadStripe()
    }
  }

  export const MISCONFIGURED_OBJECT_LABELS: Record<
    MisconfiguredStripeObjectType,
    { title: string; description: string }
  > = {
    product_deleted: {
      title: "Stripe Product Missing",
      description:
        "The associated product has been deleted in Stripe. This prevent users from registering.",
    },
    price_deleted: {
      title: "Stripe Price Missing",
      description:
        "The associated price has been deleted in Stripe. Users cannot register without a valid price.",
    },
    price_changed: {
      title: "Changed Price in Stripe",
      description:
        "The associated price has been changed in Stripe. This prevents users from registering with the correct price.",
    },
    product_archived: {
      title: "Stripe Product Archived",
      description:
        "The associated product has been archived in Stripe. This prevents users from registering.",
    },
    price_archived: {
      title: "Stripe Price Archived",
      description:
        "The associated price has been archived in Stripe. Users cannot register without a valid price.",
    },
    "%future added value": {
      title: "",
      description: "",
    },
  }
}

export default StripeUtils
