/**
 * @generated SignedSource<<d07f4da2bae59d33faceb1ecdf550d8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MisconfiguredStripeObjectType = "price_archived" | "price_changed" | "price_deleted" | "product_archived" | "product_deleted" | "%future added value";
export type MisconfiguredStripeObjectsListItemQuery$variables = {
  id: string;
};
export type MisconfiguredStripeObjectsListItemQuery$data = {
  readonly record: {
    readonly id?: string;
    readonly type?: MisconfiguredStripeObjectType;
  } | null;
};
export type MisconfiguredStripeObjectsListItemQuery = {
  response: MisconfiguredStripeObjectsListItemQuery$data;
  variables: MisconfiguredStripeObjectsListItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MisconfiguredStripeObjectsListItemQuery",
    "selections": [
      {
        "alias": "record",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "MisconfiguredStripeObject",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MisconfiguredStripeObjectsListItemQuery",
    "selections": [
      {
        "alias": "record",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "MisconfiguredStripeObject",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da3b1989c538a70f35660177b72eb311",
    "id": null,
    "metadata": {},
    "name": "MisconfiguredStripeObjectsListItemQuery",
    "operationKind": "query",
    "text": "query MisconfiguredStripeObjectsListItemQuery(\n  $id: ID!\n) {\n  record: node(id: $id) {\n    __typename\n    ... on MisconfiguredStripeObject {\n      id\n      type\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5024f341540ee84769eec311fc0fa24";

export default node;
