/**
 * @generated SignedSource<<7c61bb9b4f5d9bd68d24532579b6b8bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsCustomFieldsFragment$data = {
  readonly displayProfileValues: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly profileFieldId: string;
        readonly value: string;
      };
    }>;
  };
  readonly organization: {
    readonly publicCustomFields: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string;
          readonly webFormQuestion: {
            readonly type: WebFormQuestionType;
          } | null;
        };
      }>;
    };
  };
  readonly " $fragmentType": "ProfileSettingsCustomFieldsFragment";
};
export type ProfileSettingsCustomFieldsFragment$key = {
  readonly " $data"?: ProfileSettingsCustomFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsCustomFieldsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "isPrivate",
  "variableName": "isPrivate"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isPrivate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsCustomFieldsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": "publicCustomFields",
          "args": [
            {
              "kind": "Literal",
              "name": "isDefault",
              "value": false
            },
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "status",
              "value": "visible"
            }
          ],
          "concreteType": "ProfileFieldNodeConnection",
          "kind": "LinkedField",
          "name": "profileFields",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileFieldNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileField",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormQuestion",
                      "kind": "LinkedField",
                      "name": "webFormQuestion",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "displayProfileValues",
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "ProfileValueNodeConnection",
      "kind": "LinkedField",
      "name": "profileValues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileValueNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileValue",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profileFieldId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "21d66b9f0ac746ae8e715c01f034e9a4";

export default node;
