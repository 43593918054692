import { useLabel } from "@/core/context/LabelsContext"
import CurriculumEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/curriculum-emptystate.svg"
import Relay from "@/relay/relayUtils"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import ProfileSettingsProductRegistrationTableRow from "@/user/settings/subtabs/registrations/table/ProfileSettingsProductRegistrationTableRow"
import { ProfileSettingsProductRegistrationTablePaginationQuery } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsProductRegistrationTablePaginationQuery.graphql"
import { ProfileSettingsProductRegistrationTableQuery } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsProductRegistrationTableQuery.graphql"
import { ProfileSettingsProductRegistrationTable_PaginationFragment$key } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsProductRegistrationTable_PaginationFragment.graphql"
import { DiscoEmptyState, DiscoTable, DiscoTableSkeletonWithHeader } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const EXPERIENCE_PER_PAGE = 5

type Props = TestIDProps

function ProfileSettingsProductRegistrationTable(props: Props) {
  const { testid = "ProfileSettingsProductRegistrationTable" } = props
  const [activePage, setActivePage] = useState(1)
  const { profile } = useMemberProfileContext()!
  const isMobile = useIsMobile()
  const experienceLabel = useLabel("experience")

  const { organizationMembership } =
    useLazyLoadQuery<ProfileSettingsProductRegistrationTableQuery>(
      graphql`
        query ProfileSettingsProductRegistrationTableQuery($id: ID!) {
          organizationMembership: node(id: $id) {
            ... on OrganizationMembership {
              ...ProfileSettingsProductRegistrationTable_PaginationFragment
            }
          }
        }
      `,
      {
        id: profile.organizationMembership?.id || "",
      },
      {
        fetchPolicy: "network-only",
      }
    )

  const { data, refetch } = usePaginationFragment<
    ProfileSettingsProductRegistrationTablePaginationQuery,
    ProfileSettingsProductRegistrationTable_PaginationFragment$key
  >(
    graphql`
      fragment ProfileSettingsProductRegistrationTable_PaginationFragment on OrganizationMembership
      @refetchable(queryName: "ProfileSettingsProductRegistrationTablePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        id
        experienceProductMemberships: productMemberships(
          first: $first
          after: $after
          last: $last
          before: $before
          productType: "course"
        )
          @connection(
            key: "ProfileSettingsProductRegistrationTable_experienceProductMemberships"
          ) {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              role
              product {
                id
                ...ProfileSettingsProductRegistrationTableRowFragment
              }
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organizationMembership
  )

  if (!data) return null
  const productMemberships = Relay.connectionToArray(data.experienceProductMemberships)

  return (
    <DiscoTable
      sectionProps={{
        padding: 1.5,
        groovyDepths: "insideCard",
      }}
      emptyState={
        <DiscoEmptyState
          testid={`${testid}.EmptyState`}
          title={"No product registrations"}
          subtitle={`You haven't registered for any ${experienceLabel.plural}.`}
          icon={<CurriculumEmptyStateIcon />}
        />
      }
      activePage={activePage}
      setActivePage={setActivePage}
      rowsPerPage={EXPERIENCE_PER_PAGE}
      testid={"ProfileSettingsProductRegistrationTable"}
      onPaginate={refetch}
      rows={productMemberships.map((pm) => (
        <ProfileSettingsProductRegistrationTableRow
          key={pm.product.id}
          productKey={pm.product}
          productRole={pm.role}
        />
      ))}
      connection={{
        cursorsList: data.experienceProductMemberships?.edges.map((e) => e.cursor),
        totalCount: data.experienceProductMemberships?.totalCount || 0,
        pageInfo: {
          endCursor: data.experienceProductMemberships?.pageInfo.endCursor || null,
          startCursor: data.experienceProductMemberships?.pageInfo.startCursor || null,
        },
      }}
      header={
        isMobile
          ? undefined
          : [{ value: "Name", width: "65%" }, { value: "Label" }, { value: "Role" }]
      }
    />
  )
}

export function ProfileSettingsProductRegistrationTableSkeleton() {
  const isMobile = useIsMobile()
  return (
    <DiscoTableSkeletonWithHeader
      sectionProps={{
        padding: 1.5,
        groovyDepths: "insideCard",
      }}
      header={
        isMobile ? undefined : [{ value: "Name", width: "65%" }, { value: "Label" }]
      }
      rows={5}
    />
  )
}

export default Relay.withSkeleton({
  component: ProfileSettingsProductRegistrationTable,
  skeleton: ProfileSettingsProductRegistrationTableSkeleton,
})
