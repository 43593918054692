/**
 * @generated SignedSource<<c172b94343ae90662546d6ad69ff999f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsEventRegistrationTableQuery$variables = {
  id: string;
};
export type ProfileSettingsEventRegistrationTableQuery$data = {
  readonly organizationMembership: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsEventRegistrationTable_PaginationFragment">;
  } | null;
};
export type ProfileSettingsEventRegistrationTableQuery = {
  response: ProfileSettingsEventRegistrationTableQuery$data;
  variables: ProfileSettingsEventRegistrationTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileSettingsEventRegistrationTableQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileSettingsEventRegistrationTable_PaginationFragment"
              }
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileSettingsEventRegistrationTableQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OccurrenceRSVPNodeConnection",
                "kind": "LinkedField",
                "name": "occurrenceRSVPs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceRSVPNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OccurrenceRSVP",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Occurrence",
                            "kind": "LinkedField",
                            "name": "occurrence",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "datetimeRange",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "didViewerAttend",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "ProfileSettingsEventRegistrationTable_occurrenceRSVPs",
                "kind": "LinkedHandle",
                "name": "occurrenceRSVPs"
              }
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e07004ccf86d76c3fea9422d266b8fd2",
    "id": null,
    "metadata": {},
    "name": "ProfileSettingsEventRegistrationTableQuery",
    "operationKind": "query",
    "text": "query ProfileSettingsEventRegistrationTableQuery(\n  $id: ID!\n) {\n  organizationMembership: node(id: $id) {\n    __typename\n    ... on OrganizationMembership {\n      ...ProfileSettingsEventRegistrationTable_PaginationFragment\n    }\n    id\n  }\n}\n\nfragment OccurrenceWithDetailsFragment on Occurrence {\n  id\n  name\n  datetimeRange\n}\n\nfragment ProfileSettingsEventRegistrationTableRowFragment on OccurrenceRSVP {\n  occurrence {\n    id\n    name\n    datetimeRange\n    didViewerAttend\n    ...OccurrenceWithDetailsFragment\n  }\n}\n\nfragment ProfileSettingsEventRegistrationTable_PaginationFragment on OrganizationMembership {\n  id\n  occurrenceRSVPs {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...ProfileSettingsEventRegistrationTableRowFragment\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7da800c530b7f6c3576389a7c8154ea1";

export default node;
