/**
 * @generated SignedSource<<fc099f55b25ac08fbe9566ff14fea116>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsDetailsTabNotesFragment$data = {
  readonly id: string;
  readonly notes?: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserNotesItemFragment">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "ProfileSettingsDetailsTabNotesFragment";
};
export type ProfileSettingsDetailsTabNotesFragment$key = {
  readonly " $data"?: ProfileSettingsDetailsTabNotesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsDetailsTabNotesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canViewNotes"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsDetailsTabNotesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "condition": "canViewNotes",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNoteNodeConnection",
          "kind": "LinkedField",
          "name": "notes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNoteNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserNote",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserNotesItemFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "f1aa384c4a8ff7775dbdc57900d42b49";

export default node;
