import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ProfileAvatarWithDetailsSkeleton } from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import ProfileListDrawerListItem from "@/user/common/profile-list-drawer/list-item/ProfileListDrawerListItem"
import { ProfileListDrawerListItemFragment$key } from "@/user/common/profile-list-drawer/list-item/__generated__/ProfileListDrawerListItemFragment.graphql"
import {
  DiscoDivider,
  DiscoDrawer,
  DiscoDrawerHeader,
  DiscoDrawerHeaderSkeleton,
  DiscoText,
} from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import React from "react"
import { LoadMoreFn } from "react-relay"
import { OperationType } from "relay-runtime"

export type ProfileListItemType = ProfileListDrawerListItemFragment$key & {
  firstName?: string | null
  admin?: boolean
}

interface ProfileListDrawerProps {
  isOpen: boolean
  onClose: () => void
  title: React.ReactElement | string
  subtitle?: React.ReactElement | string
  users?: ProfileListItemType[]
  separateAdmins?: boolean
  contentHeader?: React.ReactNode
  emptyState?: React.ReactNode

  /** Load more users */
  loadNext?: LoadMoreFn<OperationType>
  isLoadingNext?: boolean
  hasNext?: boolean
  membersPerLoad?: number
  totalCount?: number
}

function ProfileListDrawer({
  isOpen,
  onClose,
  title,
  subtitle,
  users = [],
  separateAdmins = false,
  contentHeader,
  emptyState,
  isLoadingNext,
  loadNext,
  hasNext = false,
  membersPerLoad = 100,
  totalCount,
}: ProfileListDrawerProps) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const { product_admin, product_member, organization_member } = useLabels()
  const theme = useTheme()

  const admins = separateAdmins ? users.filter((user) => user.admin) : []
  const members = separateAdmins ? users.filter((user) => !user.admin) : []

  return (
    <DiscoDrawer
      open={isOpen}
      onClose={onClose}
      size={"medium"}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
    >
      {/* Header */}
      <DiscoDrawerHeader
        testid={"ProfileListDrawer"}
        onClose={onClose}
        title={title}
        subtitle={subtitle}
        disableExpand
        className={classes.header}
      />
      {!users.length && emptyState ? (
        <>{emptyState}</>
      ) : (
        <div>
          {separateAdmins ? (
            <>
              {contentHeader ? (
                <>
                  <div className={classes.container}>{contentHeader}</div>
                  <DiscoDivider marginTop={2} />
                </>
              ) : null}
              {/* Admins */}
              {Boolean(admins.length) && (
                <div className={classes.container}>
                  <div className={classes.groupNameAndTag}>
                    <DiscoText variant={"body-xs-600-uppercase"} color={"text.disabled"}>
                      {activeProduct ? product_admin.plural : "Admins"}
                    </DiscoText>

                    <DiscoTag
                      name={admins.length.toString()}
                      color={theme.palette.groovy.blue[400]}
                      backgroundColor={theme.palette.groovy.blue[100]}
                    />
                  </div>

                  <div className={classes.list}>
                    {admins.map((admin, idx) => (
                      <ProfileListDrawerListItem
                        key={admin.firstName}
                        userKey={admin}
                        testid={`ProfileListDrawerListItem-${idx}.admin`}
                      />
                    ))}
                  </div>
                </div>
              )}
              {/* Members */}
              {Boolean(members.length) && (
                <div className={classes.container}>
                  <div className={classes.groupNameAndTag}>
                    <DiscoText variant={"body-xs-600-uppercase"} color={"text.disabled"}>
                      {activeProduct ? product_member.plural : organization_member.plural}
                    </DiscoText>

                    <DiscoTag
                      name={(totalCount
                        ? totalCount - admins.length
                        : members.length
                      ).toString()}
                      color={theme.palette.groovy.blue[400]}
                      backgroundColor={theme.palette.groovy.blue[100]}
                    />
                  </div>

                  <div className={classes.list}>
                    {members.map((member, idx) => (
                      <ProfileListDrawerListItem
                        key={member.firstName}
                        userKey={member}
                        testid={`ProfileListDrawerListItem-${idx}.member`}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={classes.container}>
              {users.map((user, idx) => (
                <ProfileListDrawerListItem
                  key={user.firstName}
                  userKey={user}
                  testid={`ProfileListDrawerListItem-${idx}`}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {/* Paginate */}
      {hasNext && loadNext && (
        <DiscoScrolledIntoView
          isLoading={isLoadingNext}
          onScrolledIntoView={() => loadNext(membersPerLoad)}
        />
      )}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawerContainer: {
    padding: theme.spacing(0, 3, 3, 3),
    overflow: "auto",
  },
  header: {
    padding: theme.spacing(1.5, 3, 0, 3),
  },
  container: {
    marginTop: theme.spacing(2),
  },
  list: {
    marginTop: theme.spacing(1.5),
  },
  groupNameAndTag: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  skeletonItem: {
    marginBottom: theme.spacing(1.5),
  },
}))

export function ProfileListDrawerSkeleton({
  isOpen = true,
}: Partial<ProfileListDrawerProps>) {
  const classes = useStyles()

  return (
    <DiscoDrawer open={isOpen} size={"medium"}>
      <DiscoDrawerHeaderSkeleton />
      <div className={classes.container}>
        {range(10).map((i) => (
          <div key={i} className={classes.skeletonItem}>
            <ProfileAvatarWithDetailsSkeleton />
          </div>
        ))}
      </div>
    </DiscoDrawer>
  )
}

export default ProfileListDrawer
