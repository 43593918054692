/**
 * @generated SignedSource<<d3ea9cb76ae162926a688516e821f0e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateStripeCustomerPortalSessionInput = {
  returnUrl: string;
};
export type ProfileSettingsAccountTab_OpenCustomerPortalMutation$variables = {
  input: CreateStripeCustomerPortalSessionInput;
};
export type ProfileSettingsAccountTab_OpenCustomerPortalMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly url: string | null;
  };
};
export type ProfileSettingsAccountTab_OpenCustomerPortalMutation = {
  response: ProfileSettingsAccountTab_OpenCustomerPortalMutation$data;
  variables: ProfileSettingsAccountTab_OpenCustomerPortalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateStripeCustomerPortalSessionResponse",
    "kind": "LinkedField",
    "name": "createStripeCustomerPortalSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileSettingsAccountTab_OpenCustomerPortalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileSettingsAccountTab_OpenCustomerPortalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01028a8dff5eeebae9f32eb71ad1005b",
    "id": null,
    "metadata": {},
    "name": "ProfileSettingsAccountTab_OpenCustomerPortalMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileSettingsAccountTab_OpenCustomerPortalMutation(\n  $input: CreateStripeCustomerPortalSessionInput!\n) {\n  response: createStripeCustomerPortalSession(input: $input) {\n    url\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd2786cff64c6f84f313da88d291b7ac";

export default node;
