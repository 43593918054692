/**
 * @generated SignedSource<<1b3809485adcedaa78778f9eb0d7033e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileListDrawerListItemFragment$data = {
  readonly firstName: string | null;
  readonly fullName: string;
  readonly id: string;
  readonly lastName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  readonly " $fragmentType": "ProfileListDrawerListItemFragment";
};
export type ProfileListDrawerListItemFragment$key = {
  readonly " $data"?: ProfileListDrawerListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileListDrawerListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileListDrawerListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7aa85e2c0a82e0cd30fdc4b2841f5f70";

export default node;
