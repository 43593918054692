import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { ProfileListDrawerListItemFragment$key } from "@/user/common/profile-list-drawer/list-item/__generated__/ProfileListDrawerListItemFragment.graphql"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import { getUserFullName } from "@/user/util/userUtils"
import { DiscoText } from "@disco-ui"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ProfileListDrawerListItemProps {
  userKey: ProfileListDrawerListItemFragment$key
  testid?: string
}

function ProfileListDrawerListItem({ userKey, testid }: ProfileListDrawerListItemProps) {
  const classes = useStyles()

  const user = useFragment<ProfileListDrawerListItemFragment$key>(
    graphql`
      fragment ProfileListDrawerListItemFragment on User {
        id
        firstName
        lastName
        fullName
        ...ProfileAvatarFragment
      }
    `,
    userKey
  )

  const avatarAndName = (
    <>
      {/* Avatar */}
      <ProfileAvatar
        testid={`${testid}.MultipleAvatars.${user.firstName}`}
        userKey={user}
        size={40}
      />

      {/* Name */}
      <DiscoText
        variant={"body-sm"}
        testid={`${testid}.name`}
        color={"text.secondary"}
        className={classNames(classes.text, "fs-mask")}
      >
        {user.firstName
          ? user.fullName
          : getUserFullName({ first_name: null, last_name: null })}
      </DiscoText>
    </>
  )

  return (
    <>
      {user.id ? (
        <div className={classes.container} data-testid={`${testid}.root`}>
          <OpenProfilePopoverButton
            className={classes.baseButton}
            testid={`${user.firstName}${user.lastName}.ProfilePopover`}
            userId={user.id}
          >
            <div className={classes.container}>{avatarAndName}</div>
          </OpenProfilePopoverButton>
        </div>
      ) : (
        <div className={classes.container} data-testid={`${testid}.root`}>
          {avatarAndName}
        </div>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  baseButton: {
    padding: "0px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1.5),
    gap: theme.spacing(1.5),
  },
  text: {
    fontWeight: 500,
  },
}))

export default ProfileListDrawerListItem
