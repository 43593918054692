import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import ProfileListModalListItem from "@/user/common/profile-list-modal-list-item/ProfileListModalListItem"
import { ProfileListModalListItemFragment$key } from "@/user/common/profile-list-modal-list-item/__generated__/ProfileListModalListItemFragment.graphql"
import { DiscoModal, DiscoText } from "@disco-ui"
import { capitalizeWord } from "@utils/string/stringUtils"

export type ProfileListModalUser = ProfileListModalListItemFragment$key & {
  firstName: string | null
  lastName: string | null
}

interface ProfileListModalProps {
  isOpen: boolean
  onClose: () => void
  users: ProfileListModalUser[]
  modalTitle?: string
  totalUsers?: number
}

function ProfileListModal({
  isOpen,
  onClose,
  users,
  modalTitle,
  totalUsers = 0,
}: ProfileListModalProps) {
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const memberLabel = activeProduct ? labels.product_member : labels.organization_member
  const title = modalTitle ? capitalizeWord(modalTitle) : `All ${memberLabel.plural}`

  return (
    <DiscoModal
      testid={"ProfileListModal"}
      isOpen={isOpen}
      onClose={onClose}
      modalContentLabel={title}
      title={title}
      width={"400px"}
      body={
        <>
          {users.map((user, idx) => (
            <ProfileListModalListItem
              key={user.firstName}
              userKey={user}
              testid={`ProfileListModalListItem-${idx}`}
              onClose={onClose}
            />
          ))}
          {/** Show a "And more..." to indicate we are not showing the full members list */}
          {users.length < totalUsers && (
            <DiscoText color={"groovy.grey.300"}>
              {`And +${totalUsers - users.length} more...`}
            </DiscoText>
          )}
        </>
      }
    />
  )
}

export default ProfileListModal
